import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Similar Portfolios`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/keller-bold-asset-allocation/"
            }}>{`Bold Asset Allocation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/keller-lethargic-asset-allocation/"
            }}>{`Lethargic Asset Allocation`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: momentum strategy`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking equities, fixed income, and hard assets`}</li>
        <li parentName="ul">{`Rebalancing schedule: monthly`}</li>
        <li parentName="ul">{`Taxation: 90% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $5,000`}</li>
      </ul>
      <p>{`Keller’s `}<em parentName="p">{`Hybrid Asset Allocation`}</em>{` aims to provide a healthy, diversified asset allocation with superior protection against sudden market downturns. To achieve this goal, the strategy uses TIPS as a canary asset to detect market turbulence and rotate from risky assets into defensive positions. Thanks to its monthly rebalancing schedule and docile behavior, the strategy appeals to a wide range of risk-aware investors.`}</p>
      <p>{`The strategy was developed by Wouter J. Keller, a researcher at the VU University Amsterdam and the CEO of Flex Capital, an asset management firm based in the Netherlands. He published his `}<em parentName="p">{`Hybrid Asset Allocation`}</em>{` in a paper released in the spring of 2023.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><a parentName="p" {...{
            "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4346906"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "597px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "141%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAYAAABh2p9gAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC70lEQVRIx51WiW7qMBDs//9eFWLnvk8C5QhQCuzTTGL6WowqgWTZG9vj3dlZm7fr9Sr43W63l5rZi984jvJmAF/9/Q/8A7BtGonCQIIgEN/zJPB98bQW7XmiXVd83+dcVZZSVZXkRSFd1z33sGtbaZpWjsejHMZR9vs9F6A/HA5smHvm5QNgU9cShqFUFTyo6UVZljykyHNpmoZ2kReSpqlkWca1+Pb5+WkHRIhKadF6DtnzJQpD8TyPY601Q8fBYRhwDHA7YNOI1kqiKBLfDyRJYkmShDZA4zgicBzHnOP3uZ1OFsC6qkQpRc9MYownE9AEGAYBI4C3mHddV3a7/SPgahhmXiopq0qKomDL85wty3Op65pzsMuikO12S5DL5WLX4TjuSfI4Hl7S5A/AYbkkwUmS0gPwlybJ5F2WsQd/RZHz0CxN2cNLq4dY6LoL8uQqJZ6naS8WC9HMvL7PK9elrZUrjrMgkD0pXGiI1+L502YDgkN8ykdRMmhKa9lsNpZK6Vpml6GmKRffpYFsB4FE8SQXhI4GeaGSrJUyDAOziBJs20aWfT/ZXSctv7XUqhn3fU97/fFhTwpIB0fQIrSllWLYsBeOQy4xRujgzazJstwOCI/SNGFGEXI6hw47iWNunOyUtDDsKJLVamUHLMuCHoAjbACf4A48xTN3+I7qAKg5GJGdTqdHwL7vuMHoD4txy5ixSRaAwetm8yHr9ZrNqkMIVZO/SXvgCDeP4zgcu/MlixqHtLyZX0RyPn/ZaxnKh3ymjNb3zKKBq/P5zPDQcGUZ++vL4iE24zTwBc2Zu45JSWLJ0mzmNZIonrSJJKJUDcavpJSUB0uKZebex4rhQz7fckHV4ABQstlsHwHx+Cgu/L7r0GOTMpqcuUT//v4ueV6Qmt1uZ8tyzwxOIU4ZRaWAClRP1/X3KkFmsRlAKD1rUnbbLR8ngNRNw8uirqt7UmCjXw6DXC7Xv+/Dvx7zZw/804f+1b8gt1/v8j/BJDv1q0hAigAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Wouter J. Keller, Jan Willem Keuning: Dual and Canary Momentum with Rising Yields/Inflation: Hybrid Asset Allocation (HAA)",
                "title": "Wouter J. Keller, Jan Willem Keuning: Dual and Canary Momentum with Rising Yields/Inflation: Hybrid Asset Allocation (HAA)",
                "src": "/static/adbfdd3a51a44d1e30bd916cc9f89e1d/17602/keller-hybrid-asset-allocation.png",
                "srcSet": ["/static/adbfdd3a51a44d1e30bd916cc9f89e1d/5a46d/keller-hybrid-asset-allocation.png 300w", "/static/adbfdd3a51a44d1e30bd916cc9f89e1d/17602/keller-hybrid-asset-allocation.png 597w"],
                "sizes": "(max-width: 597px) 100vw, 597px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></a></p>
        <p><strong parentName="p">{`Source`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4346906"
            }}>{`Dual and Canary Momentum with Rising Yields/Inflation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://papers.ssrn.com/sol3/cf_dev/AbsByAuth.cfm?per_id=1935527"
            }}>{`Wouter J. Keller`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://indexswingtrader.blogspot.com/"
            }}>{`Jan Willem Keuning`}</a></li>
        </ul>
        <p><strong parentName="p">{`Learn More`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/fbertram/TuringTrader/blob/develop/BooksAndPubsV2/Keller_HAA_v2.cs"
            }}>{`Source code`}</a></li>
        </ul>
      </Aside>
      <p>{`Keller’s `}<em parentName="p">{`Hybrid Asset Allocation`}</em>{` exists in multiple flavors that differ in their universe’s composition. Our implementation of Keller’s strategy is true to the publication’s `}<em parentName="p">{`HAA-Balanced`}</em>{` variant. The strategy’s operation can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`trade a universe of eight risky assets covering U.S. equities, foreign equities, alternative assets, and U.S. bonds`}</li>
        <li parentName="ul">{`rank the risky universe by momentum and pick the top half; disqualify each asset with negative momentum`}</li>
        <li parentName="ul">{`use TIPS as a canary to detect market turbulence; disqualify the entire risky universe when the canary shows negative momentum`}</li>
        <li parentName="ul">{`consider a defensive universe of T-bills and intermediate-term treasuries`}</li>
        <li parentName="ul">{`replace all previously disqualified assets with the top-ranking defensive asset`}</li>
      </ul>
      <p>{`Refer to Keller’s excellent paper for full detail and the rationale behind Hybrid Asset Allocation. Review the strategy’s C# source code, which is included in the TuringTrader.org open-source project repository for additional insight.`}</p>
      <p>{`Keller’s strategy successfully solves a typical problem of tactical investment strategies: Balancing longer momentum periods and reduced tendency of whipsaws with swift and decisive action in times of crisis. Keller’s refined approach of using a canary asset to detect market meltdowns is highly functional yet charmingly simple. This simplicity is essential in confidently following the strategy’s asset selections without second-guessing.`}</p>
      <p>{`Using TIPS as a canary asset is a proxy for considering macro-economic cues to guide the strategy's operation. Inflation-protected treasuries, or TIPS for short, are linked to interest-rate risk as well as inflation. `}</p>
      <h2>{`Diversification`}</h2>
      <p>{`Keller’s strategy primarily trades eight risky assets spanning four asset classes. By picking four assets, the strategy is always diversified across two asset classes – unless it fled to safe assets. As a result, the `}<em parentName="p">{`Hybrid Asset Allocation`}</em>{` has a low beta and, thanks to using momentum to select the safe asset, also low interest-rate risk.`}</p>
      <p>{`Thanks to this guaranteed diversification, Keller’s strategy does not suffer from hidden tail risk as many other strategies do. In addition, combining relative and absolute momentum with the canary asset provides three independent mechanisms to disqualify assets, significantly reducing risk and uncertainty.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`Overall, the `}<em parentName="p">{`Hybrid Asset Allocation`}</em>{` shows very docile behavior with steady returns over the last economic cycle: `}<em parentName="p">{`HAA`}</em>{` gracefully handled the 2008 recession, the short-lived downturns of 2019 and 2020, and the 2022 period of stagflation. Over the whole backtesting period, the strategy’s downside stays close to its benchmark, while avoiding the deep drawdowns of recessionary periods. However, the strategy has a notable flat spot in 2013/14, where it was missing out on a sustained market rally.`}</p>
      <p>{`The Monte Carlo simulation shows that Keller’s strategy offers tremendous value to investors: `}<em parentName="p">{`Hybrid Asset Allocation`}</em>{` achieves higher returns than the 60/40 benchmark while at the same time significantly reducing major drawdowns and speeding up recovery from these.`}</p>
      <p>{`Regardless of these positive aspects, investors should manage their expectations and consider this strategy a ‘win more by losing less’ approach. In most years, the strategy’s results are only on par with the passive 60/40 benchmark, with most of the outperformance achieved during recessions. Keller’s paper includes a 50-year backtest, which confirms how the strategy’s lead over the benchmark unfolds over the long term, and is interrupted by several prolonged periods of underperformance.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`Just like other strategies rotating assets, this strategy frequently causes taxable events. The chart above shows that investors cannot expect `}<em parentName="p">{`Hybrid Asset Allocation`}</em>{` to hold any asset long enough to qualify for long-term treatment of capital gains. Consequently, `}<em parentName="p">{`HAA`}</em>{` works best in tax-deferred accounts.`}</p>
      <p>{`Keller’s strategy invests in up to 4 ETFs simultaneously. Therefore, it requires an account size of no less than $5,000 to function correctly.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, March 2023`}</a>{`: Initial release.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      